import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
    }

    body {
        font-family: 'Montserrat', sans-serif;
    }

    h1{
        font-size: 2.4rem;
    }
    li, 
    button, 
    label, 
    input, 
    p,
    a {
        font-size: 2.5rem;
        text-decoration: none;
        color: black;
    }

    h2{
        font-size: 4rem;
    }

    h3 {
        font-size: 2.5rem;
        font-weight: normal;
    }

    h4,
    h5 {
        font-size: 2rem;
    }
    ul{
        list-style: none;
    }
    a {
        color: #2c2c2c;
        text-decoration: none;
    }

    @media screen and (max-width: 1332px) {
        html {
            font-size: 53%;
        }
    }

    @media screen and (max-width: 754px) {
        html {
            font-size: 45%;
        }
    }

    /* SITE COLORS
    orange - #d36738
    blue - #44A4DA
    */

`;

export default GlobalStyle;