import React from "react";
// Page Components
import ContactSection from "../components/ContactSection";
// Animations
import { motion } from 'framer-motion';
import { pageAnimation } from "../animation";

const Contact = () => {
    return(
        <motion.div
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            <ContactSection />
        </motion.div>
    );
};

export default Contact;