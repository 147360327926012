import React from "react";
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import { motion } from "framer-motion";
import logoImg from '../img/touch_pic.png';
import { HomeActive } from "../styles";

const Nav = () => {
    const activepath = useLocation();
    return(
        <MainHead>
            <nav>
                <Logo>
                    <Link id="logo" to="/"><img src={logoImg} alt="TouchPoint Web Design" /></Link>
                    <Link to="/"><HomeActive>TouchPoint Web Design</HomeActive></Link>
                </Logo>
                <Links>
                    <Link to="/services">Services</Link>
                    <Link to="/projects">Projects</Link>
                    <Link to="/contact">Contact</Link>
                </Links>
            </nav>
        </MainHead>
    );
};

const MainHead = styled.header`
    width: 95%;
    margin: 0 0 0 auto;
    nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        min-height: 10vh;
        width: 60%;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 1332px) {
        nav {
            width: 80%;
        }
    }

    @media screen and (max-width: 754px) {
        width: 100%;
        display: flex;
        justify-content: center;
        nav {
            ul {
                flex: 1 1 60rem;
                margin: 2rem 0rem;
            }
        }
    }
`;

const Logo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex: 1 1 10rem;
    h1{
        margin: 2rem;
    }

    @media screen and (max-width: 754px) {
        display: flex;
        flex-direction: column;
        #logo {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex: 1 1 10rem;
        }
    }
`;

const Links = styled.ul`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex: 1 1 40rem;
`;

export default Nav;