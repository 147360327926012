import React, { useRef, useState } from "react";
// Framer Motion
import {motion} from 'framer-motion';
// Animation
import { fade, } from "../animation";
// Styled Components
import styled from 'styled-components';
// EmailJs
import emailjs from '@emailjs/browser';

const ContactSection = () => {
    const form = useRef();
    
    const [name, setName] = useState('');
    const [bizName, setBizName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_io199el', 'template_gbsnd9a', form.current, 'Bv_hLNLV5bkd4kKZm')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          setName('');
          setBizName('');
          setEmail('');
          setMessage('');
          e.target.reset();
    };

    return(
        <FormSection>
            <motion.form ref={form} onSubmit={sendEmail}>
                <motion.label for="name" variants={fade}>Name *</motion.label> 
                <motion.input 
                    type="text" 
                    name="name" 
                    onChange={e => setName(e.target.value)}
                    variants={fade}
                    required 
                />

                <motion.label for="email" variants={fade}>Email *</motion.label> 
                <motion.input 
                    type="email" 
                    name="email" 
                    onChange={e => setEmail(e.target.value)}
                    variants={fade}
                    required 
                />

                <motion.label for="bizName" variants={fade}>Business Name</motion.label> 
                <motion.input 
                    type="text" 
                    name="bizName" 
                    onChange={e => setBizName(e.target.value)}
                    variants={fade}
                />

                <motion.label for="message" variants={fade}>Message *</motion.label> 
                <motion.textarea 
                    name="message" 
                    cols="30" 
                    rows="10" 
                    onChange={e => setMessage(e.target.value)}
                    variants={fade}
                    required
                ></motion.textarea>

                {name.length > 0 && email.length > 0 && message.length > 0 ? (
                    <FormButton value="Send" type="submit" id="submitBtn" variants={fade} />
                ) : 
                    <FormButton value="Send" type="submit" id="submitBtn" variants={fade} disabled />
                } 
            </motion.form>
        </FormSection>
    );
};

const FormSection = styled(motion.div)`
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 3rem;
    margin-bottom: 3rem;
    
    form {
        padding: 3rem 10rem;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2), 
                    0px 20px 40px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 2rem;

        input,
        textarea {
            margin: 2rem;
            font-family: "Montserrat", sans-serif;
            &:focus {
                outline: 2px solid black;
            }
        }
        textarea {
            font-size: 2rem;
        }
        button {
            cursor: pointer;
            width: 100%;
            padding: 2rem;
            background: #44A4D4;
            color: white;
            border-radius: 10px;
            &:focus, &:hover {
                background: #d36738;
            }
        }
    }

    @media screen and (max-width: 754px) {
        form {
            padding: 2rem;
            width: 100%;
        }
    }
`;

const FormButton = styled(motion.input)`
    cursor: pointer;
    width: 100%;
    padding: 2rem;
    background: #44A4D4;
    color: white;
    border-radius: 10px;
    &:focus, &:hover {
        background: #d36738;
    }

    @media screen and (max-width: 754px) {
        width: 85%;
    }
`;

export default ContactSection;