import React from "react";
// Framer Motion
import {motion} from 'framer-motion';
// Animation
import { fade, } from "../animation";
// Styled Components
//import { About, Description, Hide } from "../styles";
import styled from 'styled-components';

const ServicesSection = () => {
    return(
        <Services>
            <Card variants={fade}>
                <a href="../pdfs/TouchPoint Web Design Individual Package.pdf" target="_blank">
                    <CardHead>
                        <h2>Individual Website Design</h2>
                        <Price><u>$900.00</u></Price>
                    </CardHead> 

                    <CardText>
                        <p>For the busy professional, artist or whoever you may be in need of a beautiful website!</p>
                    </CardText>

                    <CardFoot>
                        <button>Learn More</button>
                    </CardFoot>
                </a>
            </Card>

            <Card variants={fade}>
                <a href="../pdfs/TouchPoint Web Design Individiual Website Design Maintenance Package.pdf" target="_blank">
                    <CardHead>
                        <h2>Individual Website Design + Maintenance</h2>
                        <Price><u>$900.00 + $15.00/mo</u></Price>
                    </CardHead> 

                    <CardText>
                        <p>For the busy professional or artist that needs a website and a professional to maintain it!</p>
                    </CardText>

                    <CardFoot>
                        <button>Learn More</button>
                    </CardFoot>
                </a>
            </Card>

            <Card variants={fade}>
                <a href="../pdfs/TouchPoint Web Design Business Website Design Maintenance Package.pdf" target="_blank">
                    <CardHead>
                        <h2>Business Website Design + Maintenance</h2>
                        <Price><u>$1,250.00 + $20.00/mo</u></Price>
                    </CardHead> 

                    <CardText>
                        <p>For the small business looking to go to the next level and build an awesome website for their customers!</p>
                    </CardText>

                    <CardFoot>
                        <button>Learn More</button>
                    </CardFoot>
                </a>
            </Card>
            
        </Services>
    );
};

const Services = styled(motion.div)`
    width: 90%;
    margin: auto;
    min-height: 90vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
`;

const Card = styled(motion.div)`
    width: 50rem;
    height: 100%;
    border: 1px solid black;
    border-radius: 5px;
    margin: auto;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    background: linear-gradient(45deg, #d36738, #44A4DA);
    color: white;
    transition: 250ms;

    :hover{
        transform: translateY(-20px);
        cursor: pointer;
    }
`;

const CardHead = styled(motion.div)`
    margin-bottom: 2rem;
`;

const CardText = styled(motion.div)`
    text-align: center;
    p {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
`;

const CardFoot = styled(motion.div)`
    button {
        background: #38a4d3;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        border-radius: 35px;
        font-size: 4rem;
        margin-bottom: 2rem;
        cursor: pointer;    
        font-family: 'Montserrat', sans-serif;    
    }
`;

const Price = styled(motion.div)`
    margin-top: 1rem;
    font-size: 4rem;
`;

export default ServicesSection;