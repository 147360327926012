// Styled Components
import styled from 'styled-components';
import {motion} from 'framer-motion';

export const HomeActive = styled(motion.h1)`
    background: #44A4DA;
    color: white;
    padding: 1rem 1.4rem;
    border-radius: 3rem;
    text-align: center;
`;