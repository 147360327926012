import React from "react";
// Framer Motion
import {motion} from 'framer-motion';
// Animation
import { fade, } from "../animation";
// Styled Components
//import { About, Description, Hide } from "../styles";
import styled from 'styled-components';
// Images
import ayya from '../img/ayya-site.png';
import adney from '../img/adneyWebsite.png';
import demoMarket from '../img/demoMarket.png';
import msm from '../img/msm.png';

const ProjectSection = () => {
    return(
        <Projects>
            <Block variants={fade}>
                <h2>Shenuque Tissera</h2>
                <a href="https://shenuquetissera.com/" target="_blank"><img src={ayya} alt="Shenuque Tissera Website" /></a>
            </Block>
            <Block variants={fade}>
                <h2>Adney Silva</h2>
                <a href="https://adney.netlify.app/" target="_blank"><img src={adney} alt="Adney Silva Website" /></a>
            </Block>
            <Block variants={fade}>
                <h2>Demo Marketplace</h2>
                <a href="https://next-ecommerce-shenalt.vercel.app/" target="_blank"><img src={demoMarket} alt="Demo E-Commerce Website" /></a>
            </Block>
            <Block variants={fade}>
                <h2>Many Sided Media</h2>
                <a href="https://www.manysidedmedia.com/" target="_blank"><img src={msm} alt="Many Sided Media Website" /></a>
            </Block>
        </Projects>
    );
};

const Projects = styled(motion.div)`
    width: 90%;
    margin: auto;
    min-height: 90vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;

    h2 {
        margin: 5rem;
    }
    img {
        width: 80%;
        border-radius: 10px;
    }
`;

const Block = styled(motion.div)`
    flex: 1 1 40rem;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 754px) {
        flex: 1 1 50rem;
        img {
            width: 100%;
            height: 100%;
        }
    }
`;

export default ProjectSection;