import React from "react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
// Global Style
import GlobalStyle from "./components/GlobalStyle";
// Animation
import {AnimatePresence} from 'framer-motion';
// Router
import { Routes, Route, useLocation } from 'react-router-dom';
// Pages
import About from "./pages/About";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import Contact from "./pages/Contact";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <GlobalStyle />
      <Nav />
      <AnimatePresence 
        mode="wait"
        onExitComplete={() => {
          window.scrollTo(0, 0);
      }}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" exact element={<About />} />
          <Route path="/home" exact element={<About />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/projects" exact element={<Projects />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
