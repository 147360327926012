import React from "react";
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import { motion } from "framer-motion";
import logoImg from '../img/touch_pic.png';
import { HomeActive } from "../styles";

const Footer = () => {
    return(
        <Foot>
            <h4>TouchPoint Web Design &copy; 2023</h4>
        </Foot>
    );
};

const Foot = styled(motion.footer)`
    background: #d36738;
    color: white;
    min-height: 10vh;
    padding: 0% 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    h4 {
        flex: 1 1 40rem;
    }
`;

export default Footer;